
import { Vue, Component, mixins, Prop, Watch } from 'nuxt-property-decorator';
import { TIMERS } from '~/constants/timers';
import Swiper from 'swiper';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import DeviceMixin from '~/mixins/device.mixin';
import Banner from '@/components/widgets/common/Banner.vue';
import cuid from 'cuid';

@Component({
  name: 'dn-banners-carousel',
  components: {
    Banner,
  },
})
export default class BannersCarousel extends mixins(DeviceMixin) {
  $refs!: Vue['$refs'] & {
    carousel: Vue & {
      $el: HTMLElement;
    };
  };

  @Prop({ type: Array, required: true }) items!: any;
  @Prop({ type: Number, default: 892 / 484 }) aspectRatio!: number;
  @Prop({ type: Boolean, default: false }) autoSlide!: boolean;
  @Prop({ type: Boolean, default: false }) showArrows!: boolean;
  @Prop({ type: Boolean, default: true }) areControlsVisible!: boolean;
  @Prop({ type: Number }) perPage!: number;

  private swiper: Swiper | null = null;
  private cuid: string = '';
  private isHover: boolean = false;

  private get isArrowVisible(): boolean {
    return this.showArrows && this.isHover && this.items.length > 1;
  }

  private get swiperName(): string {
    return `swiper-${this.cuid}`;
  }

  created() {
    this.cuid = cuid();
  }

  mounted() {
    this.$nextTick(() => {
      if (this.items.length > 1) {
        this.swiper = new Swiper(`#${this.swiperName}`, {
          modules: [Navigation, Autoplay, Pagination],
          slidesPerView: this.perPage || 'auto',
          slidesPerGroup: this.perPage || 1,
          spaceBetween: 32,
          shortSwipes: true,
          longSwipesRatio: this.isMobile ? 0.2 : 0.1,
          speed: TIMERS.SLIDE_CHANGE,
          pagination: {
            el: `.swiper-controls.${this.swiperName}`,
            clickable: true,
          },
          autoplay: {
            delay: TIMERS.BANNER_SLIDE,
            disableOnInteraction: false,
          },
          loop: true,
          navigation: {
            nextEl: `.swiper-button-next.${this.swiperName}`,
            prevEl: `.swiper-button-prev.${this.swiperName}`,
          },
        });
      }
    });
  }

  private onCarouselHover() {
    if (this.isMobile) return;
    this.isHover = true;
    if (this.swiper) {
      this.swiper.autoplay.stop();
    }
  }

  private onCarouselLeave() {
    if (this.isMobile) return;
    this.isHover = false;
    if (this.swiper) {
      this.swiper.autoplay.start();
    }
  }

  @Watch('isMobile')
  reinitSwiper() {
    try {
      this.swiper?.update();
      this.swiper?.updateSlides();
    }catch (e) {
      console.warn('Banner did not reinit properly on resolution change. Refresh the page', e);
    }
  }
}
