
import { mixins, Component, Prop } from 'nuxt-property-decorator';
import DeviceMixin from '~/mixins/device.mixin';
import Banner from '~/models/banner';
import { CAROUSEL_SIZE } from '~/constants/carousel-size';
import WidgetMixin from '~/mixins/widget.mixin';

@Component({
  name: 'dn-banner-list-auto-custom',
})
export default class BannerListAutoCustom extends mixins(
  DeviceMixin,
  WidgetMixin
) {
  @Prop({ type: Number }) perPage!: number;

  private get bannerRatio() {
    return CAROUSEL_SIZE.DESKTOP_WIDTH / CAROUSEL_SIZE.DESKTOP_HEIGHT;
  }

  private get bannerMobileRatio() {
    return CAROUSEL_SIZE.MOBILE_WIDTH / CAROUSEL_SIZE.MOBILE_HEIGHT;
  }

  private get sliderItems(): Banner[] {
    return this.data?.banners || [];
  }

  private get slidesPerPage() {
    return this.isMobile ? 1 : this.perPage;
  }
}
