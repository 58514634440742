import { render, staticRenderFns } from "./BannerListAutoCustom.vue?vue&type=template&id=464ff82e"
import script from "./BannerListAutoCustom.vue?vue&type=script&lang=ts"
export * from "./BannerListAutoCustom.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionHeader: require('/frontend/components/widgets/CardCarousel/sub/SectionHeader.vue').default,BannersCarousel: require('/frontend/components/widgets/BannersCarousel/BannersCarousel.vue').default})
